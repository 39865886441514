import { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { GRID_GAP } from '@consts/consts';
import { DefaultRadius } from '@czechtvPackagesOverrides/styles/fromCTV/index';

const smartTableClassName = "smartTable"
const smartTableWrapperClassName = "smartTableWrapper"
const navigationContainerClassName = 'smartTableNavigationContainer'
const navigationClassName = 'smartTableNavigation'
const leftNavigationClassName = 'smartTableNavigationLeft'
const rightNavigationClassName = 'smartTableNavigationRight'

const styles = {
  '@global': {
    [`.${smartTableWrapperClassName}`]: {
      position: 'relative',
      left: '0px',
      overflowX: 'auto',
      borderSpacing: '0px',
      borderCollapse: 'separate',
      '&.actived': {
        overflowX: 'hidden',
      }
    },
    [`.${smartTableClassName}`]: {
      minWidth: '100%',
      position: 'relative',
      left: '0px',
    },
    [`.${navigationContainerClassName}`]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: `${GRID_GAP * 2}px`,
    },
    [`.${navigationClassName}`]: {
      width: '40px',
      height: '40px',
      backgroundColor: '#FFFFFF',
      border: 'none',
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
      borderRadius: `${DefaultRadius * 2}px`,
      cursor: 'pointer',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zODcxIDQuMjA5N0MxMS43NDY3IDMuOTMwMSAxMi4yNTMzIDMuOTMwMSAxMi42MTI5IDQuMjA5N0wxMi43MDcxIDQuMjkyODlMMTIuNzkwMyA0LjM4NzFDMTMuMDY5OSA0Ljc0NjcgMTMuMDY5OSA1LjI1MzMgMTIuNzkwMyA1LjYxMjlMMTIuNzA3MSA1LjcwNzExTDcuNDE0IDEwLjk5OUwyMCAxMUMyMC41NTIzIDExIDIxIDExLjQ0NzcgMjEgMTJDMjEgMTIuNTEyOCAyMC42MTQgMTIuOTM1NSAyMC4xMTY2IDEyLjk5MzNMMjAgMTNMNy40MTQgMTIuOTk5TDEyLjcwNzEgMTguMjkyOUwxMi43OTAzIDE4LjM4NzFDMTMuMDk1MyAxOC43Nzk0IDEzLjA2NzYgMTkuMzQ2NiAxMi43MDcxIDE5LjcwNzFDMTIuMzQ2NiAyMC4wNjc2IDExLjc3OTQgMjAuMDk1MyAxMS4zODcxIDE5Ljc5MDNMMTEuMjkyOSAxOS43MDcxTDQuMjkyODkgMTIuNzA3MUw0LjIwOTcgMTIuNjEyOUMzLjkzMDEgMTIuMjUzMyAzLjkzMDEgMTEuNzQ2NyA0LjIwOTcgMTEuMzg3MUw0LjI5Mjg5IDExLjI5MjlMMTEuMjkyOSA0LjI5Mjg5TDExLjM4NzEgNC4yMDk3WiIgZmlsbD0iIzA0MUU0MiIvPgo8L3N2Zz4K)',
      '&:disabled': {
        backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4zODcxIDQuMjA5N0MxMS43NDY3IDMuOTMwMSAxMi4yNTMzIDMuOTMwMSAxMi42MTI5IDQuMjA5N0wxMi43MDcxIDQuMjkyODlMMTIuNzkwMyA0LjM4NzFDMTMuMDY5OSA0Ljc0NjcgMTMuMDY5OSA1LjI1MzMgMTIuNzkwMyA1LjYxMjlMMTIuNzA3MSA1LjcwNzExTDcuNDE0IDEwLjk5OUwyMCAxMUMyMC41NTIzIDExIDIxIDExLjQ0NzcgMjEgMTJDMjEgMTIuNTEyOCAyMC42MTQgMTIuOTM1NSAyMC4xMTY2IDEyLjk5MzNMMjAgMTNMNy40MTQgMTIuOTk5TDEyLjcwNzEgMTguMjkyOUwxMi43OTAzIDE4LjM4NzFDMTMuMDk1MyAxOC43Nzk0IDEzLjA2NzYgMTkuMzQ2NiAxMi43MDcxIDE5LjcwNzFDMTIuMzQ2NiAyMC4wNjc2IDExLjc3OTQgMjAuMDk1MyAxMS4zODcxIDE5Ljc5MDNMMTEuMjkyOSAxOS43MDcxTDQuMjkyODkgMTIuNzA3MUw0LjIwOTcgMTIuNjEyOUMzLjkzMDEgMTIuMjUzMyAzLjkzMDEgMTEuNzQ2NyA0LjIwOTcgMTEuMzg3MUw0LjI5Mjg5IDExLjI5MjlMMTEuMjkyOSA0LjI5Mjg5TDExLjM4NzEgNC4yMDk3WiIgZmlsbD0iI0M2QzZDNiIvPgo8L3N2Zz4K)',
        opacity: '0.8',
        cursor: `not-allowed`,
      },
    },
    [`.${leftNavigationClassName}`]: {
      transform: 'scaleX(1);',
    },
    [`.${rightNavigationClassName}`]: {
      transform: 'scaleX(-1);',
    },
  }
}

type TSmartTableNavigation = {
  elm: HTMLElement,
  toLeft: HTMLElement | null,
  toRight: HTMLElement | null,
}

const wrappHtmlElement = (element: HTMLElement, wrapper: HTMLElement) => {
  element.parentElement?.insertBefore(wrapper, element)
  wrapper.appendChild(element)
}

const setDisabled = (element: HTMLElement, disabled: boolean) => {
  if (disabled) {
    element.setAttribute('disabled', 'disabled')
  } else {
    element.removeAttribute('disabled')
  }
}

const getShiftStep = (table: HTMLTableElement, wrapper: HTMLElement): number => {
  // vypocet kroku pro posouvani
  // nejuzsi sloupec - 10 px
  let shiftStep = 0
  const elms = table.querySelector('tr')?.querySelectorAll('td, th')
  if (elms && elms?.length > 0) {
    Array.from(elms).forEach((cell, i: number) => {
      const cellWith = (cell as HTMLElement).offsetWidth
      shiftStep = i == 0 ? cellWith : Math.min(shiftStep, cellWith)
    })
  }
  return shiftStep < 10 ? 10 : shiftStep
}

const handleNavigationLeft = (table: HTMLTableElement, wrapper: HTMLElement, navigation: TSmartTableNavigation) => {
  const shiftStep = getShiftStep(table, wrapper)
  let newPosition = table.offsetLeft + shiftStep
  table.style.left = `${newPosition}px`
  updateTableDisplay(table, wrapper, navigation)
}

const handleNavigationRight = (table: HTMLTableElement, wrapper: HTMLElement, navigation: TSmartTableNavigation) => {
  const shiftStep = getShiftStep(table, wrapper)
  let newPosition = table.offsetLeft - shiftStep
  table.style.left = `${newPosition}px`
  updateTableDisplay(table, wrapper, navigation)
}

const updateTableDisplay = (table: HTMLTableElement, wrapper: HTMLElement, navigation: TSmartTableNavigation) => {
  const maxOffset = wrapper.offsetWidth - table.offsetWidth

  // maxima polohy tabulky
  if (table.offsetLeft > 0) {
    table.style.left = `0px`
  }
  else if (table.offsetLeft < maxOffset) {
    table.style.left = `${maxOffset}px`
  }

  // navigace
  navigation.elm.style.display = table.offsetWidth > wrapper.offsetWidth ? '' : 'none'
  navigation.toLeft && setDisabled(navigation.toLeft, table.offsetLeft == 0)
  navigation.toRight && setDisabled(navigation.toRight, table.offsetLeft == maxOffset)
}

const createNavigation = (table: HTMLTableElement, wrapper: HTMLElement) => {
  const navigationWrapper = document.createElement("div")
  navigationWrapper.classList.add(navigationContainerClassName)
  const navigationHTML = document.getElementById('smartTableNavigationTemplate')?.innerHTML
  if (!navigationHTML) return

  navigationWrapper.innerHTML = navigationHTML
  wrapper.appendChild(navigationWrapper)
  navigationWrapper.style.display = 'none'

  const navigation: TSmartTableNavigation = {
    elm: navigationWrapper,
    toLeft: navigationWrapper.querySelector(`.${leftNavigationClassName}`),
    toRight: navigationWrapper.querySelector(`.${rightNavigationClassName}`),
  }

  // aktivace zobrazovani/skryvani navigace
  window.addEventListener('resize', () => {
    updateTableDisplay(table, wrapper, navigation)
  })
  wrapper.classList.add('actived')
  setTimeout(() => {
    updateTableDisplay(table, wrapper, navigation)
  }, 500)

  // posouvani tabulky
  if (navigation.toLeft) {
    navigation.toLeft.addEventListener('click', () => {
      handleNavigationLeft(table, wrapper, navigation)
    })
  }
  if (navigation.toRight) {
    navigation.toRight.addEventListener('click', () => {
      handleNavigationRight(table, wrapper, navigation)
    })
  }

}

const createSmartTable = (table: HTMLTableElement) => {
  // trida pro tabulku
  table.classList.add(smartTableClassName)

  // vytvoreni wrapperu
  const wrapper = document.createElement("div")
  wrapper.classList.add(smartTableWrapperClassName)
  wrappHtmlElement(table, wrapper)

  // vytvoreni navigace
  createNavigation(table, wrapper)

}

export const SmartTableProvider = ({ children }: any) => {
  const useStyles = createUseStyles(styles)
  useStyles()

  useEffect(() => {
    const unsmartedTables = Array.from(
      document.querySelectorAll("table")
    ).filter(table => !table?.parentElement?.classList.contains(smartTableWrapperClassName))
    unsmartedTables.forEach(table => {
      createSmartTable(table)
    })
  })

  return (
    <>
      {children ? children : null}
      <div id="smartTableNavigationTemplate" className={navigationContainerClassName} style={{ display: 'none' }}>
        <button className={`${navigationClassName} ${leftNavigationClassName}`}></button>
        <button className={`${navigationClassName} ${rightNavigationClassName}`}></button>
      </div>
    </>
  )
}
